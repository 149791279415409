<template>
  <div>
    <CCard>
      <CCardBody>
        <CRow>
          <CCol md="6">
            <div class="font-weight-bold mb-2">{{$t("EDIT_PLAN_PAGE_TITLE_STAKING_INFORMATION")}}</div>
            <CForm>
              <CInput :label="$t('EDIT_PLAN_PAGE_LABEL_PLATFORM')" horizontal :value="data.platform" />
              <CInput :label="$t('EDIT_PLAN_PAGE_LABEL_NAME')" horizontal :value="data.name" />
              <CInput :label="$t('EDIT_PLAN_PAGE_LABEL_SYMBOL')" horizontal :value="data.symbol" />
              <CInput
                :label="$t('EDIT_PLAN_PAGE_LABEL_TOKEN_CONTRACT')"
                horizontal
                :value="data.tokenContract"
              />
              <CInput
                :label="$t('EDIT_PLAN_PAGE_LABEL_LOCKING_CONTRACT')"
                horizontal
                :value="data.lockingContract"
              />
            </CForm>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>

    <CCard>
      <CCardBody>
        <CRow>
          <CCol col="12">
            <div class="font-weight-bold mb-2">{{$t('EDIT_PLAN_PAGE_TITLE_PLAN_INFORMATION')}}</div>
            <CForm>
              <CRow>
                <CCol md="7">
                  <CInput :label="$t('EDIT_PLAN_PAGE_LABEL_DURATION')" value="2" horizontal />
                </CCol>
                <CCol md="5">
                  <CSelect :label="$t('EDIT_PLAN_PAGE_LABEL_UNIT')" horizontal :options="durations" />
                </CCol>
              </CRow>
              <CRow>
                <CCol md="7">
                  <CInput :label="$t('EDIT_PLAN_PAGE_LABEL_EPY')" value="5%" horizontal />
                </CCol>
                <CCol md="5">
                  <CRow>
                    <CCol col="3">{{$t('EDIT_PLAN_PAGE_LABEL_ACTIVE')}}</CCol>
                    <CCol col="9">
                      <CSwitch
                        color="success"
                        :checked="active"
                        variant="3d"
                        v-bind="labelIcon"
                      />
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>

              <div col="12" class="font-weight-bold mb-2">{{$t("EDIT_PLAN_PAGE_LABEL_REWARD")}}</div>
              <CRow>
                <CCol md="7">
                  <CRow alignVertical="center">
                    <CCol col="6" sm="3">
                      <h6 class="mt-1">{{$t("EDIT_PLAN_PAGE_LABEL_REWARD_DIFFERENT_TOKEN")}}</h6>
                    </CCol>
                    <CCol col="6" sm="9">
                      <CSwitch
                        color="success"
                        :checked="data.active"
                        variant="3d"
                        v-bind="labelIcon"
                      />
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <CSelect
                    :label="$t('EDIT_PLAN_PAGE_TEXT_SELECT_PLATFORM')"
                    horizontal
                    :options="optionsPlatform"
                  />
                </CCol>
                <CCol>
                  <CInput
                    :label="$t('EDIT_PLAN_PAGE_LABEL_TOKEN_ADDRESS')"
                    horizontal
                    :value="data.address"
                  />
                </CCol>
              </CRow>

              <div class="text-center">
                <CButton color="primary" class="pl-4 pr-4">{{$t('EDIT_PLAN_PAGE_BUTTON_SAVE')}}</CButton>
              </div>
            </CForm>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: {
        platform: "ETH",
        name: "Infinito",
        symbol: "INFT",
        tokenContract: "0xABC...",
        lockingContract: "0xABC..."
      },
      durations: ["DAY", "WEEK", "MONTH"],
      active: true,
      labelIcon: {
        labelOn: "\u2713",
        labelOff: "\u2715"
      },
      optionsPlatform: ["ETH-ERC20", "ETH-ERC21"]
    };
  }
};
</script>
